@import "./_mantine.scss";
.drawerCloseButton {
  margin-right: 5px;
  border: 2px solid var(--mantine-color-blue-8);
  svg {
    width: 24px;
    height: 24px;
    stroke-width: 2px;
    color: var(--mantine-color-blue-8);
  }
}
.drawerContent {
  background-color: #f7f7f7;
}
.drawerHeader {
  background-color: #f7f7f7;
}
.drawerBody {
  overflow: scroll;
  margin-bottom: 5rem;
}
.card {
  background-color: #fff;
  border-radius: 12px;
  padding: 0px;
  margin: 0px;
  overflow: hidden;
  :hover {
    cursor: pointer;
  }

  @media (min-width: var(--mantine-breakpoint-sm)) {
    width: 350px;
  }
}
.cardLeftSection {
  font-size: var(--mantine-font-size-lg);
  color: var(--mantine-color-blue-8);
  font-weight: 700;
  line-height: 24.5px;
}
.cardRightSection {
  font-size: var(--mantine-font-size-sm);
  font-weight: 700;
  color: var(--mantine-color-blue-6);
  max-width: 60%;
  text-align: end;
}
.accordionChevron {
  display: none;
}
.accordionItem {
  background-color: var(--mantine-color-white);
  border-radius: 12px;
  margin-block: var(--mantine-spacing-xs);
}

.footerButtonsContainer {
  box-shadow: '0px 6px 80px rgba(0, 0, 0, 0.07), 0px 2.50666px 33.4221px rgba(0, 0, 0, 0.0503198), 0px 1.34018px 17.869px rgba(0, 0, 0, 0.0417275), 0px 0.751293px 10.0172px rgba(0, 0, 0, 0.035), 0px 0.399006px 5.32008px rgba(0, 0, 0, 0.0282725), 0px 0.166035px 2.21381px rgba(0, 0, 0, 0.0196802);';
}
