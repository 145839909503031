@import "./_mantine.scss";
.headerSearchSection {
  background-image: url('/gradientBlueBg.webp');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  min-height: 550px;
  margin-top: -24px;
  @media (max-width: 820px) {
    min-height: fit-content;
  }
}
.searchWrapper {
  margin-top: 40px;
  margin-left: auto;
  margin-right: auto;
  max-width: 773px;
  @media (max-width: 820px) {
    margin-top: var(--mantine-spacing-sm);
    margin-bottom: var(--mantine-spacing-xl);
    padding-left: var(--mantine-spacing-md);
    padding-right: var(--mantine-spacing-md);
    max-width: 100%;
  }
}
.activeSearchMobile {
  @media (max-width: 820px) {
    padding-bottom: var(--mantine-spacing-sm);
  }
}
.titleWrapper {
  color: white;
  margin-top: calc(var(--mantine-spacing-lg) * 2);
  @media (max-width: $mantine-breakpoint-sm) {
    margin-top: var(--mantine-spacing-lg);
    text-align: center;
  }
  @media (max-width: 375px) {
    font-size: 22px;
    margin-left: 4px;
  }
}
.title {
  font-weight: 900;
  font-size: 53px;
  line-height: 63px;
  margin-bottom: var(--mantine-spacing-md);
  color: var(--mantine-color-blue-8);
  @media (max-width: $mantine-breakpoint-sm) {
    margin-top: var(--mantine-spacing-xl);
    padding-top: var(--mantine-spacing-lg);
    margin-bottom: var(--mantine-spacing-sm);
    font-size: 28px;
    line-height: 28px;
    text-align: center;
  }
}
.subTitle {
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  margin-bottom: calc(2rem * 2);
  color: #5d6277;
  @media (max-width: $mantine-breakpoint-sm) {
    font-size: var(--mantine-font-size-lg);
    line-height: 24px;
    width: 80%;
    margin: 0 auto;
  }
}
.emphasizedTitle {
  color: var(--mantine-color-pink-6);
}
.image {
  margin-top: var(--mantine-spacing-lg);
  @media (max-width: $mantine-breakpoint-sm) {
    display: none !important;
  }
}
.backgroundWrapper {
  background-image: url('/blue-shapes-bg.png');
  background-size: 100% auto;
  background-repeat: no-repeat;
}
.footer {
  font-size: 20px;
  font-weight: 700;
  color: var(--mantine-color-blue-8);
  text-align: center;
  padding-bottom: var(--mantine-spacing-lg);
  @media (max-width: $mantine-breakpoint-sm) {
    font-size: var(--mantine-font-size-xs);
  }
}
.footerLinks {
  padding-bottom: var(--mantine-spacing-xl);
  padding-top: 50px;
  a {
    font-size: var(--mantine-font-size-md);
    font-weight: 700;
    color: var(--mantine-color-blue-8);
    text-align: center;
    text-decoration: none;
    &:hover {
      color: var(--mantine-color-lime-5);
    }
  }
  @media (max-width: $mantine-breakpoint-sm) {
    padding-top: var(--mantine-spacing-lg);
    display: flex;
    flex-direction: column;
  }
}
.divider {
  display: inline;
  width: 0;
  height: 0;
  margin-right: 10px;
  margin-left: 10px;
  padding-left: 5px;
  border-radius: 20px;
  background-color: var(--mantine-color-lime-5);
  @media (max-width: $mantine-breakpoint-sm) {
    display: none;
  }
}
.footerCopyright {
  font-size: var(--mantine-font-size-md);
  margin: 0 auto;
}
.carouselWrapper {
  & :global(.CarouselWidget-prefix) {
    display: block;
  }
}
.modalBody {
  height: 100%;
}
.filterHeader {
  color: var(--mantine-color-blue-8);
  font-weight: 700;
  font-size: var(--mantine-font-size-md);
  line-height: 16px;
  opacity: 0.7;
}
.mobileSearchIcon {
  color: var(--mantine-color-blue-8);
  margin-left: var(--mantine-spacing-xs);
}
.card {
  background-color: #fff;
  border-radius: 200px;
  padding: 0.25rem;
  box-shadow: 0px 4px 24px 0px #0b174e1a;
  border: 1px solid var(--mantine-color-gray-2);
  &:hover {
    cursor: pointer;
  }
  width: 75%;
}
